<template>
  <v-card data-cy="user_settings-menu">
    <v-divider></v-divider>
    <v-list subheader>
      <v-subheader>Account</v-subheader>
      <v-list-tile v-for="(item, key) in userDetails" avatar :key="key">
        <v-list-tile-avatar>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-content>
          <v-list-tile-sub-title>{{ item.sub }}</v-list-tile-sub-title>
          <v-list-tile-title>{{ user[key] }}</v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>
      <v-list-tile avatar>
        <v-list-tile-avatar>
          <v-icon>perm_identity</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-content>
          <v-list-tile-sub-title>Role</v-list-tile-sub-title>
          <v-list-tile-title>{{ roles[role.name] }}</v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>
      <v-list-tile avatar v-if="client && client.name">
        <v-list-tile-avatar>
          <v-icon>apartment</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-content>
          <v-list-tile-sub-title>Client</v-list-tile-sub-title>
          <v-list-tile-title>{{ client.name }}</v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>
    </v-list>
    <v-divider></v-divider>
    <v-list subheader>
      <v-subheader>Alerts</v-subheader>
      <v-tooltip left color="sdark">
        <v-list-tile slot="activator">
          <v-list-tile-avatar>
            <div @click="stopPropagation">
              <v-switch
                class="ma-0"
                hide-details
                color="primary"
                v-model="user.play_alarm_sounds"
                @click.native="toggleSetting('play_alarm_sounds')"
                :disabled="status == 'LOADING'"
              ></v-switch>
            </div>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-title>Sound</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <span>
          A sound will {{ user.play_alarm_sounds ? '' : 'not' }} be played when
          an alert is triggered
        </span>
      </v-tooltip>
    </v-list>
    <v-divider></v-divider>
    <v-list subheader>
      <v-subheader>Actions</v-subheader>
      <v-list-tile
        to="/users-management"
        v-if="isAuthorized('manager')"
        data-cy="nav-users-managements"
      >
        <v-list-tile-action>
          <v-icon>people</v-icon>
        </v-list-tile-action>
        <v-list-tile-content>
          <v-list-tile-title>User Management</v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>
      <v-list-tile
        to="/notifications"
        v-if="isAuthorized('site_manager')"
        data-cy="nav-notifications"
      >
        <v-list-tile-action>
          <v-icon>notifications</v-icon>
        </v-list-tile-action>
        <v-list-tile-content>
          <v-list-tile-title>Alert Settings</v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>
      <v-list-tile @click="clickModifyProfile('modifyProfile')">
        <v-list-tile-action disabled>
          <v-icon>vpn_key</v-icon>
        </v-list-tile-action>
        <v-list-tile-title>Modify Profile</v-list-tile-title>
      </v-list-tile>
      <v-list-tile @click="openDialog('logout')" data-cy="logout">
        <v-list-tile-action>
          <v-icon>exit_to_app</v-icon>
        </v-list-tile-action>
        <v-list-tile-title>Log Out</v-list-tile-title>
      </v-list-tile>
    </v-list>
  </v-card>
</template>
<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import debounce from 'lodash/debounce'
import { roles } from '@/components/Mixins/constants'

export default {
  name: 'UserMenu',
  data: () => ({
    userDetails: {
      name: { sub: 'Name', icon: 'info' },
      email: { sub: 'Email', icon: 'email' }
    },
    roles,
  }),
  computed: {
    ...mapState('users', ['status', 'user', 'client']),
    ...mapGetters('users', ['isAuthorized', 'alarm', 'role']),
    ...mapGetters('sites', ['activeSite', 'siteCenter'])
  },
  methods: {
    ...mapActions('users', ['UPDATE_USER']),
    openDialog(name) {
      this.$store.commit('users/SET_STATUS', null)
      return this.$bus.$emit('openDialog', name)
    },
    clickModifyProfile(dialogName) {
      this.$bus.$emit('OPEN_MODIFY_PROFILE_DIALOG', {
        user: {...this.user}
      })
      this.openDialog(dialogName)
    },
    stopPropagation(event) {
      event.stopPropagation()
    },
    toggleSetting(setting) {
      this.user[setting] = !this.user[setting]
      this.UPDATE_USER({
        ...this.user,
        [setting]: !this.user[setting]
      })
    },
    fetchUserClient: debounce(async function(user) {
      if (user && user.client_id) {
        await this.$store.dispatch('users/FETCH_CLIENT', user.client_id)
      }
    }, 1000),
  },
  watch: {
    async user(newUser) {
      await this.fetchUserClient(newUser)
    }
  }
}
</script>
